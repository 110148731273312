body {
  background-color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  display: flex;
  overflow: hidden;
}

.funky-button {
  color: #fff;
  cursor: pointer;
  background-color: #f07;
  border: none;
  border-radius: 20px;
  padding: 20px 40px;
  font-size: 2em;
  transition: background-color .5s;
}

.funky-button:hover {
  background-color: #c06;
}

.funky-button:active {
  background-color: #905;
}

.countdown {
  text-align: center;
  margin-top: 20px;
  font-size: 3em;
}

.instructions {
  margin-top: 30px;
  font-size: 1.2em;
}

.instructions ul {
  padding-left: 0;
  list-style-position: inside;
}

.instructions li {
  margin-bottom: 10px;
}

footer {
  text-align: center;
  background: #fff;
  width: 100%;
  padding: 1em;
  position: fixed;
  bottom: 0;
}

footer a {
  color: #000;
  margin: 0 1em;
  text-decoration: none;
}

#visualizer {
  width: 100%;
  max-width: 500px;
}

#app {
  text-align: center;
}

#countdown {
  font-size: 3em;
  animation: 3s linear fadeOut;
}

.hide {
  display: none;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

#image-container {
  width: 100%;
  height: 60vh;
  overflow: hidden;
}

#carousel img {
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
}

@media screen and (width <= 600px) {
  .funky-button {
    padding: 15px 30px;
    font-size: 1.5em;
  }

  .countdown {
    font-size: 2em;
  }

  .instructions {
    font-size: 1em;
  }
}

/*# sourceMappingURL=index.8519cd97.css.map */
