body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #ffffff;
    flex-direction: column;
    overflow: hidden;
  }
  
  .funky-button {
    font-size: 2em;
    padding: 20px 40px;
    border-radius: 20px;
    border: none;
    color: white;
    background-color: #ff0077;
    cursor: pointer;
    transition: background-color 0.5s;
  }
  
  .funky-button:hover {
    background-color: #cc0066;
  }
  
  .funky-button:active {
    background-color: #990055;
  }
  
  .countdown {
    font-size: 3em;
    text-align: center;
    margin-top: 20px;
  }
  
  .instructions {
    margin-top: 30px;
    font-size: 1.2em;
  }
  
  .instructions ul {
    list-style-position: inside;
    padding-left: 0;
  }
  
  .instructions li {
    margin-bottom: 10px;
  }
  
  footer {
    position: fixed;
    bottom: 0;
    text-align: center;
    width: 100%;
    padding: 1em;
    background: #fff;
  }
  
  footer a {
    margin: 0 1em;
    color: #000;
    text-decoration: none;
  }
  
  #visualizer {
    width: 100%;
    max-width: 500px;
  }
  
  #app {
    text-align: center;
  }
  
  #countdown {
    font-size: 3em;
    animation: fadeOut 3s linear;
  }
  
  .hide {
    display: none;
  }
  
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  #image-container {
    width: 100%;
    height: 60vh;
    overflow: hidden;
  }
  
  #carousel img {
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
  }
  
  /* Responsive styles */
  @media screen and (max-width: 600px) {
    .funky-button {
      font-size: 1.5em;
      padding: 15px 30px;
    }
  
    .countdown {
      font-size: 2em;
    }
  
    .instructions {
      font-size: 1em;
    }
  }
  